import {SET_PROFILE_INFO_DISPLAY, SET_PRINT_PROFILE, SET_MY_APPLICATION_LIST, SET_APPLICATION_DETAILS, SET_SHOW_APPLICATION_DETAILS_FLAG, SET_APPLICATION_RECEIPT, SET_CHANGE_PASS_MODAL,
    SET_UPDATE_PROFILE_MODAL,SET_PROFILE_VIEW, SET_EMAIL_MOBILE_OTP, SET_VERIFY_OTP_FLAG_EMAIL,SET_VERIFY_OTP_FLAG_MOBILE, SET_UPDATE_PROFILE_DONE, SET_BACK_TO_MY_ACCOUNT, SET_PROFILE_SUMMARY_INFO,
    SET_HAS_PENDING_TRANSACTIONS,SET_POST_PREFERENCE_DETAILS,
    SET_CHANGE_CENTER_FLAG,SET_PAYMENT_HISTORY_MODAL, SET_PAYMENT_HISTORY_LIST, SET_UPDATE_POST_PREF_FLAG ,SET_EXAM_CENTER_LIST,
    SET_APPLICATION_STAGE_USERDATA, SET_APPLICATION_RE_CHECK_ELIGIBILITY_INFO, SET_MARKSHEET_LIST, SET_APPLICATION_POST_PREF_LIST_RE_CHECK_ELIGIBILITY,GET_ALL_CANDIDATE_INFO,GET_OBJ_REQ_DATA,GET_DROPDOWN_OBJ_REQ,SET_OBJ_REQ_ID,GET_POST_OBJREP,
    GET_OBJECTION_QUESTIONS, SET_EXAM_DISPLAY_NAME_FOR_ADMIT_CARD
} from "../../common/constants/ActionTypes";

const initialState = {
    profileDetailsInfo:[],
    postPreferenceDetails:[],
    myApplicationList: [],
    applictionReceipt:'',
    changePasswordModalFlg:false,
    updateProfileModalFlg:false,
    updateProfileDone:false,
    setShowModalHide:false,
    profileUpdateEmailAndMobileOtpResponse : [],
    verifyMobileOtpFlag:false,
    verifyEmailOtpFlag:false,
    profileViewFlag:false,
    applicationDetails : [],
    showApplicationDetailsFlag: false,
    profileSummaryInfo: [],
    isPrintProfile : false,
    isPrintProfileDone: false,
    hasPendingTransactions: { applicationIds: [] },
    changeCenterModalFlag : false,
    paymentHistoryModalFlag : false,
    paymentHistoryList : [],
    updatePostPreferenceFlag : false,
    examCenterList : [],
    userDataApplicationStage : {},
    reCheckEligibilityResultList: null,
    applicationPostPrefListForReCheckEligibility : null,
    marksheetList : [],
    admitCardData:{},
    objReqData:[],
    dropDownObjReq:{},
    objReqDataById:{},
    objPostRep:{},
    objectionQuestionsList:{},
    examDisplayNameDetails : [],
};

export default function myAccountReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PROFILE_VIEW:
            return{
                ...state,
                profileViewFlag:action.payload
            }
        case SET_MY_APPLICATION_LIST:
            return {
                ...state,
                myApplicationList: action.payload,
            };
        case SET_APPLICATION_DETAILS:
            return{
                ...state,
                applicationDetails :action.payload
            }
        case SET_SHOW_APPLICATION_DETAILS_FLAG:
            return{
                ...state,
                showApplicationDetailsFlag:action.payload
            }
        case SET_PRINT_PROFILE:
            return{
                ...state,
                isPrintProfile:action.payload
            }
        case SET_PROFILE_INFO_DISPLAY:
            return{
                ...state,
                profileDetailsInfo: action.payload
            };
        case SET_POST_PREFERENCE_DETAILS:
            return{
                ...state,
                postPreferenceDetails: action.payload
            };
        case SET_APPLICATION_RECEIPT:
            return {
                ...state,
                applictionReceipt: action.payload
            }
        case SET_CHANGE_PASS_MODAL:
            return{
                ...state,
                changePasswordModalFlg:action.payload
            }
        case SET_UPDATE_PROFILE_MODAL:
            return{
                ...state,
                updateProfileModalFlg:action.payload
            }
        case SET_EMAIL_MOBILE_OTP:
            return{
                ...state,
                profileUpdateEmailAndMobileOtpResponse:action.payload
            }
        case SET_APPLICATION_RE_CHECK_ELIGIBILITY_INFO:
            return {
                ...state,
                reCheckEligibilityResultList: action.payload,
            }
        case SET_APPLICATION_POST_PREF_LIST_RE_CHECK_ELIGIBILITY:
            return {
                ...state,
                applicationPostPrefListForReCheckEligibility: action.payload
            }
        case SET_VERIFY_OTP_FLAG_EMAIL:
            return{
                ...state,
                verifyEmailOtpFlag:action.payload
            }
        case SET_VERIFY_OTP_FLAG_MOBILE:
            return{
                ...state,
                verifyMobileOtpFlag:action.payload
            }
        case SET_UPDATE_PROFILE_DONE:
            return {
                ...state,
                updateProfileDone: action.payload
            }
        case SET_BACK_TO_MY_ACCOUNT:
            return{
                ...state,
                isPrintProfileDone:action.payload
            }
        case SET_PROFILE_SUMMARY_INFO:
            return{
                ...state,
                profileSummaryInfo:action.payload
            }
        case SET_HAS_PENDING_TRANSACTIONS:
            return {
                ...state,
                hasPendingTransactions: action.payload,
            }
        case SET_CHANGE_CENTER_FLAG:
            return {
                ...state,
                changeCenterModalFlag: action.payload,
            } 
        case SET_PAYMENT_HISTORY_MODAL:
            return {
                ...state,
                paymentHistoryModalFlag: action.payload
            }
        case SET_PAYMENT_HISTORY_LIST:
            return {
                ...state,
                paymentHistoryList: action.payload
            }
        case SET_UPDATE_POST_PREF_FLAG:
            return {
                ...state,
                updatePostPreferenceFlag: action.payload,
            } 
        case SET_EXAM_CENTER_LIST:
            return {
                ...state,
                examCenterList: action.payload,
            }   
        case SET_APPLICATION_STAGE_USERDATA:
            return {
                ...state,
                userDataApplicationStage : action.payload
            }
        case SET_MARKSHEET_LIST:
            return{
                ...state,
                marksheetList: action.payload
            }  
        case GET_ALL_CANDIDATE_INFO:
            return{
                ...state,
                admitCardData : action.payload
            } 
        case GET_OBJ_REQ_DATA:
            return{
                ...state,
                objReqData:action.payload
            } 
        case GET_DROPDOWN_OBJ_REQ:
            return{
                ...state,
                dropDownObjReq : action.payload
            }
        case SET_OBJ_REQ_ID:
            return{
                ...state,
                objReqDataById : action.payload
            } 
        case GET_POST_OBJREP:
            return{
                ...state,
                objPostRep : action.payload
            }  
        case GET_OBJECTION_QUESTIONS:
            return{
                ...state,
                objectionQuestionsList : action.payload
            }  
        case SET_EXAM_DISPLAY_NAME_FOR_ADMIT_CARD:
            return{
                ...state,
                examDisplayNameDetails : action.payload
            }                                                                                
        default:
            return state;
    }
}