/* eslint-disable */
import { useEffect, useState } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { SET_SELECTED_QUALIFICATION_TYPE } from "../../common/constants/ActionTypes";
import { addQualification, editQualification, getUniversities, getSubjectsForAQualification } from '../actions/profileAction';
import { qualificationInfoReqDTO } from "../constants/qualificationInfoReqDTO";

const useQualificationActions = () => {
  const { register, control, handleSubmit, errors, setValue, reset , watch} = useForm({
    mode: "onChange"
  });
  let { qualificationsList, qualificationtypecode, qualificationToBeEdited, 
    flagQualificationEdit, qualificationNames, statesList, universities,
    subjects, currentEditQualificationIndex, isQualificationTypeChangedByDropdown, publicationTypeList, nameOfIndexingBodiesList, getPublicationArticleTypeList} = useSelector(state => state.profile, shallowEqual);
  const dispatch = useDispatch();
  const [ qualificationState, setQualificationState ] = useState(qualificationInfoReqDTO);
  const [ actionType, setActionType ] = useState('add');
  const [ isResultDateMandatory, setIsResultDateMandatory ] = useState(true);
  const [ flgSubjectDisable, setFlgSubjectDisable ] = useState(true);
  const [ flgStateDisable, setFlgStateDisable ] = useState(true);
  const [ flgUniversityDisable, setFlgUniversityDisable ] = useState(true);
  const [ isResultInfoMandatory, setIsResultInfoMandatory ] = useState(true);
  const [ flgAttemptsDisable, setFlgAttemptsDisable ] = useState(false);
  const [ flgPercentageDisable, setFlgPercentageDisable ] = useState(false);
  const [ flgNumberOfAcadmicMonthDisable, setFlgNumberOfAcadmicMonthDisable ] = useState(false);
  const [ flgClassDisable, setFlgClassDisable ] = useState(false);
  const [ flgModeDisable, setFlgModeDisable ] = useState(false);
  const [ flgCompSubjectDisable, setFlgCompSubjectDisable ] = useState(false);
  const [ flgOptSubjectDisable, setFlgOptSubjectDisable ] = useState(false);
  const [ flgNameOfRegDisable, setFlgNameOfRegDisable ] = useState(false);
  const [ flgCouncilRegDateDisable, setFlgCouncilRegDateDisable ] = useState(false);
  const [ flgCouncilRegNumDisable, setFlgCouncilRegNumDisable ] = useState(false);
  const [ flgDateOFRenewalDisable, setFlgDateOFRenewalDisable ] = useState(false);

  if(flgSubjectDisable) {
    subjects = []
  }

  if(flgStateDisable) {
    statesList = [];
  }

  if(flgUniversityDisable) {
    universities = []
  }

  const [ flgBoardTypes, setFlgBoardTypes ] = useState(false);
  const boardQualificationTypes = ["SSC", "HSC"];
  const qualificationTypesWithoutSubjects = ["BSSC", "SSC", "Pb", "Cf", "NIOS", "YCMOU", "Ph.D Guide", "Other", "MEMB"];

  useEffect(() => {
      let newUpdatedData = qualificationToBeEdited;
      newUpdatedData.indexingBodyLookupId = qualificationToBeEdited && qualificationToBeEdited.publicationQualificationInfo && qualificationToBeEdited.publicationQualificationInfo.indexingBodyLookupId ? qualificationToBeEdited.publicationQualificationInfo.indexingBodyLookupId : "";
      newUpdatedData.articleTypeLookupId = qualificationToBeEdited && qualificationToBeEdited.publicationQualificationInfo && qualificationToBeEdited.publicationQualificationInfo.articleTypeLookupId ? qualificationToBeEdited.publicationQualificationInfo.articleTypeLookupId : "";
      newUpdatedData.publicationAcceptanceDate = qualificationToBeEdited && qualificationToBeEdited.publicationQualificationInfo && qualificationToBeEdited.publicationQualificationInfo.publicationAcceptanceDate ? qualificationToBeEdited.publicationQualificationInfo.publicationAcceptanceDate : "";
      newUpdatedData.publicationPublishedDate = qualificationToBeEdited && qualificationToBeEdited.publicationQualificationInfo && qualificationToBeEdited.publicationQualificationInfo.publicationPublishedDate ? qualificationToBeEdited.publicationQualificationInfo.publicationPublishedDate : "";

    setQualificationState(newUpdatedData);
    if(qualificationToBeEdited.typeResult == 0) {
      setIsResultDateMandatory(false);
    } else {
      setIsResultDateMandatory(true);
    };

    if(qualificationToBeEdited.qualificationnamecode === 'ArmedForcesG'){
      if(qualificationToBeEdited.resultDate ==""|| qualificationToBeEdited.resultDate ==null ){
          setIsResultDateMandatory(false)
      }else{
      setIsResultDateMandatory(true)
      } 
        setIsResultInfoMandatory(false)
        setFlgSubjectDisable(true)
        setFlgStateDisable(true);
        setFlgUniversityDisable(true);
        setFlgAttemptsDisable(true)
        setFlgPercentageDisable(true)
        setFlgNumberOfAcadmicMonthDisable(true)
        setFlgClassDisable(true)
        setFlgModeDisable(true)
        setFlgCompSubjectDisable(true)
        setFlgOptSubjectDisable(true)
        setFlgNameOfRegDisable(true)
        setFlgCouncilRegDateDisable(true)
        setFlgCouncilRegNumDisable(true)
        setFlgDateOFRenewalDisable(true)
    }
  }, [qualificationToBeEdited])
  useEffect(() => {
    if(isQualificationTypeChangedByDropdown) {
      setFlgSubjectDisable(true);
      if(qualificationtypecode !== "BSSC") {
        setFlgStateDisable(true);
      } else {
        setFlgStateDisable(false);
      }
      setFlgUniversityDisable(true);
      reset();
      setQualificationState(qualificationInfoReqDTO);
      if(currentEditQualificationIndex !== -1) {
        setQualificationState({
          ...qualificationInfoReqDTO,
          qualificationtypecode: qualificationtypecode,
          id: qualificationsList[currentEditQualificationIndex].id
        });
      }
    } else {
      if(qualificationtypecode === "Other") {
        setFlgStateDisable(true);
        setFlgUniversityDisable(true);
        return;
      }
      if(!qualificationTypesWithoutSubjects.includes(qualificationtypecode)) {
        setFlgSubjectDisable(false);
      } else {
        setFlgSubjectDisable(true);
      }
      if(qualificationToBeEdited.qualificationnamecode !== 'ArmedForcesG'){
        setFlgStateDisable(false);
        setFlgUniversityDisable(false);
      }
    }

    if(boardQualificationTypes.includes(qualificationtypecode)) {
      setFlgBoardTypes(true);
    } else {
      setFlgBoardTypes(false);
    }

  }, [qualificationtypecode])

  const handleQualificationChange = (evt, name) => {
    if(name === "compulsorySubjects"){
        setQualificationState({
          ...qualificationState,
          ["compulsorySubjects"]: evt
        });
    }else if(name === "optionalSubjects"){
      setQualificationState({
        ...qualificationState,
        ["optionalSubjects"]: evt
      });
    }
    else{
        let value = evt.target.value;
        setQualificationState({
          ...qualificationState,
          [evt.target.name]: value
        });
    }
  }

  const handleResultTypeChange = (evt) => {
    let value = evt.target.value;
    let resultType = !value ? 1: Number(value);
    if(resultType === 0) {
      setValue('resultDate', "", { shouldValidate: true });
      setValue('certificationGrade', "", { shouldValidate: true });
      setValue('percentageGrade', "", { shouldValidate: true });
      setValue('courseDurationMonths', "", { shouldValidate: true });
      setIsResultDateMandatory(false);
      setQualificationState({
        ...qualificationState,
        resultDate: "",
        certificationGrade: "",
        percentageGrade: "",
        courseDurationMonths: "",
        typeResult: value
      })
    } else {
      if(qualificationState.qualificationnamecode === 'ArmedForcesG' && qualificationtypecode =='G'){
        setIsResultInfoMandatory(false)
      }else{
        setIsResultInfoMandatory(true)

      }
      setIsResultDateMandatory(true);
      setQualificationState({
        ...qualificationState,
        typeResult: value
      });
    }
  };

  const handleSubjectsId = (subjectsList) => {
      if (!subjectsList || subjectsList.length === 0) {
        return "";
      }
      const subjectIdsString = subjectsList.map(item => item.subjectId).join(',');
      return subjectIdsString;
  };

  const handleQualificationInfoSubmit = () => {
    if(actionType === "add") {
      const transformedObject = {
        ...qualificationState,
        "compulsorySubjects": handleSubjectsId(qualificationState.compulsorySubjects),
        "optionalSubjects": handleSubjectsId(qualificationState.optionalSubjects),
        "publicationQualificationInfo": {
          "indexingBodyLookupId": qualificationState.indexingBodyLookupId,
          "articleTypeLookupId": qualificationState.articleTypeLookupId,
          "publicationAcceptanceDate": qualificationState.publicationAcceptanceDate,
          "publicationPublishedDate": qualificationState.publicationPublishedDate
        }
      };
      let qualificationToBeAdded = {...transformedObject, qualificationtypecode:qualificationtypecode};
      dispatch(addQualification(qualificationToBeAdded, qualificationsList));
    }
    if(actionType === "edit") {
      const transformedObject = {
        ...qualificationState,
        "compulsorySubjects": handleSubjectsId(qualificationState.compulsorySubjects),
        "optionalSubjects": handleSubjectsId(qualificationState.optionalSubjects),
        "publicationQualificationInfo": {
          "indexingBodyLookupId": qualificationState.indexingBodyLookupId,
          "articleTypeLookupId": qualificationState.articleTypeLookupId,
          "publicationAcceptanceDate": qualificationState.publicationAcceptanceDate,
          "publicationPublishedDate": qualificationState.publicationPublishedDate
        }
      };    
      dispatch(editQualification(transformedObject, qualificationsList, currentEditQualificationIndex));
    }
    setQualificationState(qualificationInfoReqDTO);
    dispatch({
      type: SET_SELECTED_QUALIFICATION_TYPE,
      payload: "Select"
    })
  }

  const onQualificationNameChange = (e) => {
    let value = e.target.value;
    setFlgUniversityDisable(true);
    if(value !== "") {
      handleQualificationChange(e);
      if(qualificationtypecode === "G" && value==="ArmedForcesG"){
        setIsResultDateMandatory(false)
        setFlgSubjectDisable(true)
        setFlgStateDisable(true);
        setFlgUniversityDisable(true);
        setFlgAttemptsDisable(true)
        setFlgPercentageDisable(true)
        setFlgNumberOfAcadmicMonthDisable(true)
        setFlgClassDisable(true)
        setFlgModeDisable(true)
        setFlgCompSubjectDisable(true)
        setFlgOptSubjectDisable(true)
        setFlgNameOfRegDisable(true)
        setFlgCouncilRegDateDisable(true)
        setFlgCouncilRegNumDisable(true)
        setFlgDateOFRenewalDisable(true)
        setQualificationState({
          ...qualificationState,
          subjectLookupId: "",
          otherSubject: "",
          statecode: "",
          universitycode: "", 
          attempts: "",
          percentageGrade: "",
          courseDurationMonths: "",
          certificationGrade: "",
          classGradeLookupId: "",
          modeLookupId: "",
          compulsorySubjects: "",
          optionalSubjects: "",
          councilId: "",
          councilRegistrationDate: "",
          councilRegistrationNumber: "",
          councilRenewalDate: "",
          registrationDate: "",
          registrationNumber: "",
          projectTitle: "",
          publicationType: "",
          publicationAuthorName: "",
          flgPublicationFirstAuthor: "",
          publicationAuthorType: "",
          publicationJournalDetails: "",
          publicationYear: "",
          flgImpactIndex2Or0OrMore: "",
          publicationPaperTitle: "",
          recognitionDate: "",
          noStudentsRegisteredYw: "",
          noStudentsResearchComplete: "",
          noStudentsPhdAwarded: "", 
          typeResult:"",
          resultDate: "",
          indexingBodyLookupId: "",
          articleTypeLookupId: "",
          publicationAcceptanceDate: "",
          publicationPublishedDate: "",
          guidedBy:"",
          [e.target.name]: value
        });
        return;
      }else{
        setIsResultInfoMandatory(true)
        setIsResultDateMandatory(true)
        setFlgSubjectDisable(false)
        setFlgStateDisable(false);
        setFlgUniversityDisable(false);
        setFlgAttemptsDisable(false)
        setFlgPercentageDisable(false)
        setFlgNumberOfAcadmicMonthDisable(false)
        setFlgClassDisable(false)
        setFlgModeDisable(false)
        setFlgCompSubjectDisable(false)
        setFlgOptSubjectDisable(false)
        setFlgNameOfRegDisable(false)
        setFlgCouncilRegDateDisable(false)
        setFlgCouncilRegNumDisable(false)
        setFlgDateOFRenewalDisable(false)
        setQualificationState({
          ...qualificationState,
          typeResult:"",
          resultDate: "",
          [e.target.name]: value
        });
      };

      if(qualificationtypecode === "Other") {
        setFlgStateDisable(true);
        setFlgUniversityDisable(true);
        return;
      }
      if(!qualificationTypesWithoutSubjects.includes(qualificationtypecode)) {
        setFlgSubjectDisable(false);
        dispatch(getSubjectsForAQualification(value));
      } else {
        setFlgSubjectDisable(true);
      }
      setFlgStateDisable(false);
    } else {
      setQualificationState({
        ...qualificationState,
        subjectLookupId: "",
        statecode: "",
        universitycode: "",
        [e.target.name]: value
      });
      setFlgSubjectDisable(true);
      setFlgStateDisable(true);
    }
    setQualificationState({
      ...qualificationState,
      subjectLookupId: "",
      statecode: "",
      universitycode: "",
      compulsorySubjects: "",
      optionalSubjects: "",
      [e.target.name]: value
    });
    
    if(qualificationtypecode === "BSSC"){
       setQualificationState({
      ...qualificationState,
      subjectLookupId: "",
      universitycode: "",
      [e.target.name]: value
    });
    }
  }
  const onStateChange = (e) => {
    let value = e.target.value;
    if(Number(value) !== 999) {
      setFlgUniversityDisable(false);
    } else {
      setFlgUniversityDisable(true);
    }
    setQualificationState({
      ...qualificationState,
      [e.target.name]: value,
      universitycode: ""
    });

    if(flgBoardTypes) {
      dispatch(getUniversities(value, qualificationState.qualificationnamecode));
    } else {
      if(qualificationtypecode !== "BSSC") {
        dispatch(getUniversities(value));
      }
    }
  }

  return { handleQualificationChange, handleQualificationInfoSubmit, handleResultTypeChange, setActionType,
     isResultDateMandatory, register, control, handleSubmit, errors, qualificationState, setQualificationState,
     flagQualificationEdit, qualificationNames, statesList, universities, subjects, onQualificationNameChange, onStateChange,
     flgSubjectDisable, flgStateDisable, flgUniversityDisable, qualificationtypecode, currentEditQualificationIndex,
     isResultInfoMandatory,flgAttemptsDisable, flgPercentageDisable, flgNumberOfAcadmicMonthDisable,
     flgClassDisable, flgModeDisable, flgCompSubjectDisable, flgOptSubjectDisable, flgNameOfRegDisable, flgCouncilRegDateDisable,
     flgCouncilRegNumDisable, flgDateOFRenewalDisable,publicationTypeList, nameOfIndexingBodiesList, getPublicationArticleTypeList, watch
    };
}

export default useQualificationActions;